var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-absolute center-vertical width-100-percent"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{class:{
                      'text-center mt-1 promo-price-text': true,
                      'promo-price-text-product-page': _vm.productPage,
                     },attrs:{"cols":"8"},domProps:{"innerHTML":_vm._s(_vm.promoPriceText)}})],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.productPage ? 6 : 12,"lg":"10"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{class:{
                      'text-right promo-price-int': true,
                      'promo-price-int-product-page': _vm.productPage,
                      'mobile-price-size': _vm.$vuetify.breakpoint.mobile
                     },attrs:{"cols":_vm.productPage ? 7 : 9},domProps:{"innerHTML":_vm._s(Math.trunc(_vm.productPrice))}}),_c('v-col',{class:{
                      'promo-price-dec': true,
                      'promo-price-dec-product-page': true,
                     },attrs:{"cols":"3"}},[_c('v-row',{class:{
            'decimal-part-margin': true
          },attrs:{"no-gutters":""}},[_c('v-col',{class:{
              'pa-0 promo-price-dec mt-1': true,
              'promo-price-dec-product-page mt-2': _vm.productPage
            },attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.decimalPartOfPrice)}}),_c('v-col',{class:{
              'pa-0 promo-price-dec promo-price-dec-currency ma-0': true,
              'promo-price-dec-product-page': _vm.productPage
            },attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.currency)}})],1)],1)],1)],1)],1),(_vm.productPage)?_c('v-row',[_c('ProductBasketButton',{attrs:{"product":_vm.product,"platform":_vm.platform,"category":_vm.category}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }